body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.delete-icon {
  color: gray;
}

.App-fab-bottom-right {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.diaper-icon {
  font-size: 32px;
  padding-right: 16px;
}

.diaper-list-content {
  margin: auto;
}
.measurement-container {
  text-align: center;
}

.measurement-input {
  width: 90px;
  margin: auto;
}

.measurement-list-content {
  margin: auto;
}
.feeding-ammount {
  font-size: 32px;
  text-align: center;
  height: 48px;
  padding: 8px;
}

.bottle-list-icon {
  margin: auto;
}
.selectable:hover {
    cursor: pointer;
    background-clip: padding-box;
    background-color: lightgray;
}

.selectable {
    background-clip: padding-box;
}
