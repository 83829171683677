.measurement-container {
  text-align: center;
}

.measurement-input {
  width: 90px;
  margin: auto;
}

.measurement-list-content {
  margin: auto;
}